

@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"), url(./app/fonts/Nunito-Regular.ttf) format('truetype');
}  

/* ////Main-Style-Start///////// */
body {
  margin: 0;
 font-family: "Nunito-Regular", sans-serif!important;
 
  background-color: #e8e8e8 !important;
}
.HojaBookWhiteBackgroundColor{
  background-color: #ffffff!important;

}
.HojaBookWhiteColor{
  color: #ffffff!important;

}
.HojaBookMainColor{
  color: #991917!important;
}
.HojaBookMainColor1{
  color: #6c757d!important;
}
.HojaBookMainColor2{
  color: #323a46!important;
}
.HojaBookMainColor3{
  color: #000!important;
}

.HojaBookMainBackgroundBlac{
  background-color: #000!important;
}

/* Progress */
.bg-HojaBookMainBackgroundBlac {
  background-color:#e1b3b4!important;

}
.bg-HojaBookMainBackground {
  background-color:#d89e9f   !important;

}
.bg-HojaBookMainGrayBackground2 {
  background-color:#793639a1 !important;

}
.bg-HojaBookMainGrayBackground{
  background-color:#783235!important;

}
.MuiBackdrop-root {
  background: rgb(0 0 0 / 50%) !important;
}
.HojaBookMainBackgroundColor
{
  background-color:#690b09!important
}
.HojaBookMainBackgroundColor1{
  background-color: #991917!important;
}
/* Progress */
.errorColor
{
color:#dc3545;
}
.bg-progress-color{
  background-color: #783235!important;
}

.progress-bg-color{
  background-color:#783235!important;
}
.submit-empty-fields-note .submit-empty-fields-icon{
  color: #dc3545;
}
.HojaBookMainBackground{
  background-color:#991917 !important;
}
.HojaBookMainGrayBackground{
  background-color:#f7f7f7 !important;
}
.HojaBookMainGrayBackground2{
  background-color:#323a46 !important;
}

.pointer-cursor{
  cursor: pointer;
}

.Hoja-Font-Bold{
  font-weight: bold;
}
.Hoja-Font-Bold-700{
  font-weight: 700;
}
.Font-Size-14{
  font-size:14px;
}
.font-size-13{
  font-size:13px
  ;}
.Font-Size-16{
  font-size:16px;
}
.Font-Size-18{
  font-size:18px;
}
.Font-Size-20{
  font-size:20px;
}

.main-header-logo{
    max-width: 100%;
    height: auto;
}
.height-succ-300{
  height: 300px;
}
.overflow-text-length {
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: rgb(184, 180, 180);
  border-radius: 10px
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb {
  background: #343a40;
  border-radius: 10px;
}

/* ////Login-Page-Style-End///////// */

.lang-box-style , 
.lang-box-style-ar{
  border: 2px solid #991917;
  padding: 8px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  color: #272121;
  font-weight: bold;
}
.primary-text-color{
  color: #991917!important;

}

.btn.langIcon i {
  color: #991917;
} 
.kt-splash-screen{
  color: #991917!important;

}
.text-success-profile{
  color: #991917!important;

}
.btn.btn-Signout{
  background-color: #991917;
  color: #ffffff;
  border-color: transparent;

}

.btn.btn-Signout:hover:not(.btn-text), .btn.btn-Signout:focus:not(.btn-text), .btn.btn-Signout.focus {
  background-color: #E6DEDD!important;
  color: #807e7e!important;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.btn.langIcon:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.langIcon:focus:not(.btn-text), .btn.langIcon.focus:not(.btn-text) {
  color: #991917!important;
  background-color: #F3F6F9;
  border-color: transparent;
}
 

.symbol.symbol-light-user .symbol-label {
  background-color: #80808021;
  color: #000000;
  text-transform: uppercase;
}
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  fill: #E6DEDD !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #E6DEDD;
}

/* a:hover {
color: #323a46!important;

} */
.navi .navi-item .navi-link:hover .navi-text{

  color: #807e7e!important;

}
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading, .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link {
  background-color:unset!important;

}



/* ////Login-Page-Style-Start///////// */

/* ////Login-Page-Style-End///////// */



/* ////Registration-Page-Style-Start///////// */
.padding-20{
  padding: 0px 20%;
}
.padding-40{
  padding: 15px 40px;
}
.login-Registration {
  border: 1px solid #d4d4d4;
  background-color: #f7f7f7;
  /* width: 100%!important; */
  /* max-width: unset!important; */
  /* display: flex!important; */
  /* max-height: 520px; */
  /* background: white; */
  /* margin-top: 25px; */
  /* border-radius: 8px; */
  /* margin-bottom: 15px; */
  height: 100%;
}

.Registration-right-screen{
  overflow-x: auto;
  border-bottom-right-radius: 8px;
  align-self: center;
  background-color: #f7f7f7;
  border-top-right-radius: 8px;
}
/* ////Steps-----Style */


.Registration-main-steps-view{
  flex-direction: column;
  display: flex;
  margin-top: 10px;
}

.Registration-active-step {
  border: 1px solid gray;
  justify-content: center;
  color: gray;
  border-radius: 58%;
  text-align: center;
  padding: 9px;
  width: 42px;
  cursor: pointer;
}

.wizard-steps{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.wizard-wrapper{
  display: flex;
}
.marginBottomImage {
  margin-bottom: 0.35rem !important;
}
.wizard-icon{
  width: 42px;
  padding: 15px;
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  height: 40px;
  border-radius: 50px;
  margin-right: 1.4rem;
  background-color: #d0d0d0;
}

.wizard-icon-active{
  width: 42px;
    padding: 15px;
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    height: 40px;
    border-radius: 50px;
    margin-right: 1.4rem;
    background-color: #991917;
}

 .wizard-number {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.5rem;
}
.wizard-label {
  align-self: center!important;
  font-weight: 500;
  font-size: 15px;
}

/* ////Steps-----Style */



.marginBottom3{
  margin-bottom: 3.25rem !important;
}

.marginBottom1{
  margin-bottom: 1.25rem !important;

  }
  .marginTop3{
    margin-top: 5.25rem !important;
  
    }
    .marginTop2{
      margin-top: 2.25rem !important;
    
      }
      .marginTop4{
        margin-top: 3.25rem !important;
      
        }
.WelecomTitleText{
  font-size: 20px;
}

.WelecomTitleText2{
  font-size: 13px;
  text-align: center;
}

.CompanyTypeMainText{
  font-size: 16px;
}

.CreateAccountSteps-MainTitle{
  font-size: 18px;
    font-weight: bold;
    text-align: center;
}


.companyType-section-box{
  background: white;
    padding: 15px;
    border-radius: 7px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #8080803b;
    box-shadow: 5px 5px 4px 0px #8080807a;
    margin-bottom: 20px;
}
.companyType-section-box-selected-view{
  border: 2px solid #991917;
  border-radius: 4px;
}

.companyType-section-box-icon{
  font-size: 70px !important;
  color: #991917;
}


.companyType-box {
  padding: 10px !important;
}
.imageWidthCompanyType{
  /* width: 100px!important; */
  height: 100px!important;
  margin-bottom: 10px;
}
.TypeNameStyle{
 font-size: 16px;
 font-weight: 600;

 color: #000000;
}
.activeColor{
  color: #741215
}
.inv-hr-view-2{
  border-color:  #991917;
  border-width: 2px;
  width: 40px;
  margin: initial;
  margin-top: 4px;
}

.inv-hr-view-3{
  border-color:  #991917;
  border-width: 1px;
  width: 140px;
  margin: auto;
  margin-top: 4px;
}
.inv-hr-view-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgb(0 0 0 / 5%);
}
.MainButtonActionStyle{
  font-size: 14px;
    color: white;
    background: rgb(153, 25, 23);
    margin-top: -2px;
    float: right;
    padding: 5px 30px;
}

.MainButtonActionStyle:hover{
  font-size: 14px;
    color: white;
    background: #991917e3;
    margin-top: -2px;
    float: right;
    padding: 5px 30px;
}

.MainButtonActionStyle2{
  font-size: 14px;
    color: white;
    background: rgb(153, 25, 23);
    margin-top: 16px;
    float: right;
    padding: 5px 30px;
}

.MainButtonActionStyle2:hover{
  font-size: 14px;
    color: white;
    background: #991917e3;
    margin-top: 16px;
    float: right;
    padding: 5px 30px;
}
.margin-bottom2{
  margin-bottom: 2.25rem !important;

}  
.margin-bottom3{
  margin-bottom: 2.5rem !important;

}  

.Account-Tab-MainContainer-Style{
  justify-content: space-around;
  margin-top: 2.5rem;
}
.AccountTab-Box-Style{
  /* margin-top: 16px; */
  color: #991917;
  text-align: center;
  /* float: right; */
  border:0px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px dashed;
  width: -webkit-fit-content;
  width: -moz-fit-content;
background-color:unset;  

margin-bottom: 10px;
  font-size: 15px;

}
.form-group {
  margin-bottom: 1.75rem;
}
.AccountTab-Box-active-Style{
  justify-content: space-between;
}
.padding-14
{
  padding: 0px 14%;
}
.Registration .progress {
  display: flex;
  height: 1.3rem!important;
  overflow: hidden;
  line-height: 0;
  font-size: 0.9rem!important;
  background-color: #e3e3e3!important;
  border-radius: 1.42rem;
  box-shadow: none;
}


.padding-right-left-20{
  padding: 0px 20px;
}

.AccountDetailsView .form-group label {
  font-size: 15.5px;
  font-weight: 700;
  color: #3F4254;
}
.image-upload>input {
  display: none;
}
.FileIconBackground {
  margin-right: 6px;
  width: 2em!important;
  height: 2em!important;
  background-color: #991917!important;
  border-radius: 50px!important;
}
.FileIconStyle
{
  margin-top: 5px;
  color: #FFFFFF!important;
  font-Size: 18px!important;
  width: 0.79em!important;
  height: 0.9em!important;
  margin-left: 6px;
}
.margin-Top-16{
  margin-top: 1.25rem;
}
.uploadeTextStyle{
  font-size: 14.5px;
    font-weight: 700;
    color: #3F4254;
}
.disableBackground{
  background-color: rgb(0 0 0 / 20%)!important;
  
}
.disableColor{
  color: #787778!important;;
}
.disableCursor
{
  cursor: not-allowed;
}
.wlecomHoja-height300{
height:400px;
}
/* ////Registration-Page-Style-End///////// */

/* ////MasterTabele-Page-Style-Start///////// */
.width-100{
  width: 100%;
}

.master-table-view{
  background: white;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;
  padding-right: 20px;
  padding-left: 20px;
}
.table-header-row{
  color: #3F4254;;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  border-bottom: 1px solid #3F4254;


}
/*  */
.table-body-row{
  padding: 10px;
  color: black;
  border-bottom: 0px solid #8080801a;
}

.table-header-row-background:nth-child(even) {background: #e8e8e829;
  font-weight: 500!important;}
.table-header-row-background:nth-child(odd) {background: #FFF;font-weight: 500!important;}
.page-filter-view{
  background: white;
  padding: 15px;
  border-radius: 8px;
  height: auto;
}
.page-filter-view2{
  background: white;
  padding: 15px;

  border-top-left-radius:8px; ;
  border-top-right-radius:8px; ;
}
.page-filter-view3{
  background: white;
  padding: 15px;

  border-bottom-left-radius:8px; ;
  border-bottom-right-radius:8px; ;
}
.pagenation-view {
  float: right;
}

.pagination-rounded .page-link {
  background-color: white!important;
  border: 2px solid !important;
  border-color: #75727200 !important;
  border-radius: 7px;
}
.page-link { 
  color: #757272 !important;
  font-weight: 500;
}

.page-item.active .page-link {
  background-color: white!important;
  border: 1.5px solid;
  border-color: #991917 !important;
  border-radius: 7px;
}

.page-link:focus {
  box-shadow: 0 0 0 0.15rem rgb(193 187 187 / 15%) !important
}

.page-link:hover { 
  font-weight: 500;
  background-color: white!important;
  border: 1.5px solid;
  border-color: #75727200 !important;
  border-radius: 7px;
}


.table-display-limit-text-1 {
  margin-top: 6px;
  margin-right: 10px;
  font-size: 12px;
  color: #0a0000;
}
.table-display-limit-text-2 {
  margin-top: 6px;
  margin-left: 10px;
  font-size: 12px;
  color: #0a0000;
}
.Picky-FormStyle .picky__input{
  width: 100%;
  background-color: white;
  border: 0;
  border-width: 1px;
  border-color: #E4E6EF;
  border-style: solid;
  border-radius: 6px;
  padding: 5px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  height: 38px!important;
}
.Picky-FormStyle .picky__dropdown{
  max-height: 200px!important;
  overflow-y: auto!important;
}

.Picky-FormStyle .picky__input span{
  display: flex;
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
.report-refresh-btn{
  border: 1px solid #737683;
  background: #ffffff;
  font-size: 14px;
}
.showEntriesStyle{
  margin-bottom: 20px;
    float: right;
}
.table-display-limit-select  .form-control
{
  
  line-height: 1!important;
  padding: 0.15rem 0.55rem!important;
  border-bottom: 1px solid #E4E6EF!important;
  background-color: #ffffff!important;
  background-clip: padding-box!important;
  height: 30px!important;

}
.pagenation-view-next-back-style{
  font-size: 1rem!important;
  color: #B5B5C1!important;
}
.hideFiliterSection{
display: none;
  
}
.showFiliterSection{
  display: block!important;
  width: 100%;


}
.header-progress-bar
{
  color:  #991917 !important;
}

.actionDropdownListPadding-0{
  padding: 0rem .85rem;
}

.menu-dots-vertical{
  color:  #991917 ;
  font-size: 22px !important
}
.action-icon-view .nav-link.dropdown-toggle:after, .action-icon-view .btn.dropdown-toggle:after{
  display: none
}
.react-confirm-alert {
  background: white;
  padding: 20px;
  border-radius: 3px;
  width: 30%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.react-confirm-alert-overlay {
  background: rgba(39, 36, 36, 0.37) !important;
  z-index: 999999;
}

.alert-delete-icon {
  color: #f7b84b;
  border-color: #f7b84b;
  border: 2px solid;
  border-radius: 50%;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 900;
  font-size: 20px;
}
.alert-box-view {
  text-align: center;
}
.form-save-btn{
  border: 2px solid #991917;
  border-radius: 7px;
  color: #991917;
  background: white;
  font-weight: 500;
}

.form-save-btn:hover{

  color: #991917;
}
.form-cancel-btn:hover{

  color: #323a46;
}

.form-cancel-btn{
  border: 2px solid  #323a4694;
  border-radius: 7px;
  color:  #323a46;
  background: white;
  font-weight: 500;
}
.Drawer-header-data {
  background-color: #000000;
  color: #fff;
  padding: 10px;
  /* border-bottom: 4px solid #7e7e7e; */
}
.drawer-header-title {
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
}

.form-side .MuiDrawer-paperAnchorRight{
  width:480px!important;
}
.close-drawer i {
  font-size: 22px;
  /* padding-left: 25px; */
  color: white;
}
.close-drawer {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.custom-form-phone-view {
  margin-bottom: 1rem;
}
.PhoneInputInput
{

  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;


}
.error-label {
  color: #f1556c !important;
}

/* ////MasterTabele-Page-Style-End///////// */

/* ////Login-Page-Style-Start///////// */
.login-signin,
.login-forgot {
  border: 1px solid #d4d4d4;
  padding: 40px;
  background-color: #fff;
}
.login-pattern-back {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.login-login-page{
margin-top: 3%;
}

.login-login-page1{

  background: white;
  /* margin-top: 25px; */
  /* border-radius: 8px; */
  /* margin-bottom: 15px; */
  height: 100vh;
}
.dashboard-logo{
  max-width: 15%;
}

.dashboard-main-view{
  text-align: center;
  background-color: #ffff;
  padding: 10% 1%;
  /* padding: 20px; */
  border-radius: 10px;
  height: 70vh;
}


.main-button {
  color: #ffffff !important;
  background-color: #991917 !important;
  border-color: #991917 !important;
  padding: 7px 30px;
  margin-bottom: 30px;
  border-radius: 0.42rem;

}
.main-button:hover {
  background-color: #991917 !important;
  border-color: #991917 !important;
}

.loginView .form-group label {
  font-size: 15.5px;
  font-weight: 700;
  color: #6c757d;
}
.login-form-padding{
  padding-left: 6%;
  padding-right: 6%;

}
.login-header-padding{
  padding-left: 5%;
  padding-right: 5%;

}
.WelecomTitleText3{
  font-size: 14px;
  text-align: center;
}
.login-image{
  height: 450px;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  align-self: center;
}
.ForgetIcon{
  margin-right: 3px;
    margin-top: -4px;
}
.forgetView .form-group
{
  margin-bottom: 0.55rem!important;
}
/* ////Login-Page-Style-End///////// */
/* ////aside-menu-Style-start///////// */
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon svg, .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon svg {
  height: 20px;
  width: 20px;
  margin-left: -2px;
}
.aside-menu {
  background-color: #000000;
}
.aside {
  background-color: #000000;}
  .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading, .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: #000000;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text, .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  font-weight: 400;
  font-size: 1.12rem;
  text-transform: none;
  text-transform: initial;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text, .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color: #b2b2b2;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text, .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: #b2b2b2;
 
}
.aside-menu .menu-nav > .menu-section .menu-text {
  color: #ffffff;
  font-weight: 600;
}
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
  background-color: #000000!important;
}
.aside-menu .menu-nav > .menu-section .menu-text {
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: unset!important;
  letter-spacing: 0.3px;
  font-size: 16px;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon, .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  color: #e6dedd;
}
.aside-menu .menu-nav .menu-item > .menu-heading:hover, .aside-menu .menu-nav .menu-item > .menu-link:hover {
  text-decoration: none;
  cursor: pointer;
  color: #e6dedd!important;
}

.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-icon, .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon {
  color: #e6dedd;
}
a:hover {
  color: #000000!important;
  text-decoration: underline;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon, .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: #e6dedd;
}
.dropdown-item.active, .dropdown-item:active {
  color:  #101221;
  text-decoration: none;
  background-color: #EBEDF3;
}
/* ////aside-menu-Style-End///////// */
/* ////User-Style-Start///////// */
.noDataViewMargin{
  margin-top:10%;
  margin-bottom:10%;

}
.custom-form-phone-error input {
  border-color: #f1556c !important;
  padding-right: calc(1.5em + 0.9rem) !important;
}

.fileInput-span {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
  /* margin-bottom: 1rem; */


.fileInput-width {
  width: 100%;
  border-radius: 5px !important
}
.fileInput-opacity {
  opacity: 0;
  position: absolute;
  /* top: 0; */
  right: 0px;
  /* min-width: 100%; */
  /* min-height: 100%; */
  font-size: 10px;
  /* text-align: center; */
  cursor: pointer;
  outline: none;
}

.task-action-file-details-file-name {
  color: black;
  font-size: 13px;
}


.task-action-file-details-delete-icon {
  font-size: 16px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.UploadImageSize{
  margin-right: 10px;
    font-size: 0.85rem;
    color: #B5B5C3;
}.black-color {
  color: black;
}
.black-color:hover {
  color: rgb(30, 30, 255)!important;
}
.formLabel .form-group label {
  font-size: 1rem;
  font-weight: 600;
  color: #6c757d;
}
.formLabel .custom-radio .form-group label {
  font-size: 1rem!important;
  font-weight: 500!important;
  color: #6c757d;
}

.custom-form-phone-view  label {
  font-size: 1rem;
  font-weight: 600;
  color: #6c757d;
}
.custom-label {
  font-size: 1rem!important;
  font-weight: 600;
  color: #6c757d;
}
legend {
  font-weight: 600;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: inherit;
  color: inherit;
  color:#6c757d;
  white-space: normal;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #991917;
  border-color: #991917;
  background-color: #991917;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #B5B5C3 solid 1px;
  box-shadow: none;
}
.custom-control-label::after {
  right: -2rem;
  position: absolute;
  top: 0.1rem;
  left: -1.65rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}
.SaveCircularProgressColor{
  width: 15px !important;
    height: 15px !important;
    color: #ffffff !important;
   

}

.paddingDropDown{
  padding: 0.0rem 0.8rem;
}
.line-break-file-name{
line-break: anywhere;
}
.bt-color
{
  color: #ffffff;
  background-color: #0bb7afa8!important;
  border-color: #0bb7afa8!important;
}
.actionDropdownList .dropdown-menu.show {
  display: block;
  left: -50px!important;
}
 /* ////To remove icon of error/ */
.formLabel .was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F64E60;
  padding-right:10px!important;
  background-image: unset!important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.svg-icon.svg-icon-primary svg g [fill] {
  transition: fill 0.3s ease;
  fill: #991917!important;
}
/* ////User-Style-End///////// */

/* ////Banks-Style-Start///////// */
.bankIconStyle{

  width: 1.5rem!important;
  height:1.5rem!important;
  color: #991917!important;
}
.bankFormBranchpadding{
  padding-top: 2.25rem !important;
}
.scrolltop {
  display: none;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  background-color: #991917;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
  opacity: 0;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  border-radius: 0.42rem !important;
}
/* ////Banks-Style-End///////// */

/* ////BankAccount-Style-Start///////// */
.BankAccountForm .form-group {
  margin-bottom: 1rem!important;
}





/* ////BankAccount-Style-End///////// */




/* ////////////////////Basic-Media-Start////////////////// ////////*/
/* Breakpoint(X-Small) */
@media only screen
and (min-device-width:320px)
and (max-device-width: 575px) {
  .text-respnsive-left{
    text-align:left!important;
  }
  .actionDropdownList .dropdown-menu.show {
    display: block;
    left: 0px!important;
  }
}

/* Breakpoint(Small) */
@media only screen
and (min-device-width:576px)
and (max-device-width: 767px) {

  .actionDropdownList .dropdown-menu.show {
    display: block;
    left: 0px!important;
  }
}

@media only screen
and (min-device-width:768px)
and (max-device-width: 991px) {

}

@media only screen
and (min-device-width:992px)
and (max-device-width: 1024px) {


}
/* Breakpoint(Extra large) */
@media only screen
and (min-device-width:1200px)
and (max-device-width: 1399px) {
 
    
}
@media only screen
and (min-device-width:1400px)
and (max-device-width: 2499px) {
  .dashboard-main-view {
    text-align: center;
    background-color: #ffff;
    padding: 16% 1%;
    /* padding: 20px; */
    border-radius: 10px;
    height: 75vh;
}
  .login-login-page{
    margin-top: 5%;
    }
    .login-login-page4{
      margin-top: 1%;
      }
      .login-header-padding {
        padding-left: 10%;
        padding-right: 10%;
    }
    .login-form-padding {
      padding-left: 6%;
      padding-right: 11%;
  }

  .login-image {
    height: 650px;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    align-self: center;
}
}

@media (min-width: 992px)
{

.brand-dark .brand .btn .svg-icon svg g [fill] {
    transition: fill 0.3s ease;
    fill: #e6dedd;
}
.brand-dark .brand {
    background-color: #000000;
    box-shadow: none;
}
.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item:hover > .menu-link > .menu-icon {
  color: #e6dedd !important;
}
}
.brand-dark .brand .btn.active .svg-icon svg g [fill], .brand-dark .brand .btn:hover .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #e6dedd;
}
.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  width: 100%;color: #e6dedd !important;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 400px) {
  .react-confirm-alert {
    width: 90%!important;
}
.form-side .MuiDrawer-paperAnchorRight {
  width: 300px!important;
}
}

@media only screen
and (min-device-width: 401px)
and (max-device-width: 500px) {
  .react-confirm-alert {
    width: 75%!important;
}
.form-side .MuiDrawer-paperAnchorRight {
  width: 400px!important;
}
}
@media only screen
and (min-device-width: 501px)
and (max-device-width: 600px) {
  .react-confirm-alert {
    width: 65%!important;
}
}
@media only screen
and (min-device-width: 600px)
and (max-device-width: 767px) {
  .react-confirm-alert {
    width: 50%!important;
}
}
@media (min-width: 430px)
{
.dropdown-menu.dropdown-menu-xl {
    width: 200px;
}
}
/* ////////////////////Basic-Media-End////////////////// ////////*/
